exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-imprint-tsx": () => import("./../../../src/pages/imprint.tsx" /* webpackChunkName: "component---src-pages-imprint-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kunstwerke-flopsidopsi-tsx": () => import("./../../../src/pages/kunstwerke/flopsidopsi.tsx" /* webpackChunkName: "component---src-pages-kunstwerke-flopsidopsi-tsx" */),
  "component---src-pages-kunstwerke-goldene-giraffe-tsx": () => import("./../../../src/pages/kunstwerke/goldene-giraffe.tsx" /* webpackChunkName: "component---src-pages-kunstwerke-goldene-giraffe-tsx" */),
  "component---src-pages-kunstwerke-papas-lieblingstiere-tsx": () => import("./../../../src/pages/kunstwerke/papas-lieblingstiere.tsx" /* webpackChunkName: "component---src-pages-kunstwerke-papas-lieblingstiere-tsx" */),
  "component---src-pages-kunstwerke-regenbogen-biene-tsx": () => import("./../../../src/pages/kunstwerke/regenbogen-biene.tsx" /* webpackChunkName: "component---src-pages-kunstwerke-regenbogen-biene-tsx" */),
  "component---src-pages-kunstwerke-schluessel-schatz-tsx": () => import("./../../../src/pages/kunstwerke/schluessel-schatz.tsx" /* webpackChunkName: "component---src-pages-kunstwerke-schluessel-schatz-tsx" */),
  "component---src-pages-kunstwerke-schnipseldipsel-tsx": () => import("./../../../src/pages/kunstwerke/schnipseldipsel.tsx" /* webpackChunkName: "component---src-pages-kunstwerke-schnipseldipsel-tsx" */),
  "component---src-pages-kunstwerke-tsx": () => import("./../../../src/pages/kunstwerke.tsx" /* webpackChunkName: "component---src-pages-kunstwerke-tsx" */),
  "component---src-pages-musik-index-tsx": () => import("./../../../src/pages/musik/index.tsx" /* webpackChunkName: "component---src-pages-musik-index-tsx" */),
  "component---src-pages-musik-songplayer-tsx": () => import("./../../../src/pages/musik/songplayer.tsx" /* webpackChunkName: "component---src-pages-musik-songplayer-tsx" */),
  "component---src-pages-spiele-index-tsx": () => import("./../../../src/pages/spiele/index.tsx" /* webpackChunkName: "component---src-pages-spiele-index-tsx" */),
  "component---src-pages-spiele-memory-index-tsx": () => import("./../../../src/pages/spiele/memory/index.tsx" /* webpackChunkName: "component---src-pages-spiele-memory-index-tsx" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

